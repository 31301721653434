import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import DatenschutzModal from '../modals/Datenschutz';
import ImpressumModal from '../modals/Impressum';


function Footer() {

    

    //console.log(location);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenImp, setIsModalOpenImp] = useState(false);

    const closeModal = () => {
        setIsModalOpen(false);
      };
      const openModal = () => {
        setIsModalOpen(true);
        console.log(isModalOpen);
      };

      const closeModalImp = () => {
        setIsModalOpenImp(false);
      };
      const openModalImp = () => {
        setIsModalOpenImp(true);
      };
      
    return (
        <>
             <footer className="mainFooter py-4">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <ul className="footerMenu">
                            <li><NavLink to="/werbung" className="nav__link ">Aktuelle Werbung</NavLink></li>
                            <li><NavLink to="/aktionen" className="nav__link ">AKTIONEN</NavLink></li>
                            <li><NavLink to="/artikel" className="nav__link ">Artikelsuche</NavLink></li>
                        </ul>
                    </div>
                    <div className="col-lg-3">
                        <ul className="footerMenu">
                            <li><NavLink to="/maxithek" className="nav__link ">MAXITHEK</NavLink></li>
                            <li><NavLink to="/filialen" className="nav__link ">KONTAKT</NavLink></li>
                        </ul>
                    </div>
                    <div className="col-lg-3">
                        <ul className="footerMenu">
                            <li><a href="javascript:;" onClick={openModalImp} className="nav__link ">IMPRESSUM</a></li>
                            <li><a href='javascript:;' onClick={openModal} className="nav__link ">DATENSCHUTZ</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-3">
                        <div className="socialIcons d-flex justify-content-between align-items-start">
                            <div className="ms-right"><a href="/technikkenner"><img src={`${process.env.PUBLIC_URL}/assets/images/Logo_MS Media.png`} alt="" /></a></div>                            
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <DatenschutzModal 
        closeModal={closeModal} 
        isModalOpen={isModalOpen}
        />
        <ImpressumModal 
        closeModal={closeModalImp} 
        isModalOpen={isModalOpenImp}
        />

        </>
   


    )
}

export default Footer